<template>
  <div class="container">
    <h1>Translations</h1>
  </div>
</template>

<script>
export default {
  name: "Translation"
};
</script>

<style scoped></style>
